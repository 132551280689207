<template>
  <div style="text-align: right;height: 48px;">
    <el-button v-if="features" style="margin-right:32px;" @click="saveAndSyncChange"
      >保存并将修改项同步其他分区</el-button
    >
    <el-button v-if="features" @click="setFeatures">保存设置</el-button>
  </div>

  <el-card class="outer-card radius-20">
    <template v-if="features">
      <el-row :gutter="36" class="my-6">
        <el-col :span="4">
          <switch-card v-model="features.beiBao">背包</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.jiaoYi">交易</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.zuDui">组队</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.zhaoMu">邮箱</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.xiaoTui">小退</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.hongBao">红包</switch-card>
        </el-col>
      </el-row>

      <el-row :gutter="36" class="my-6">
        <el-col :span="4">
          <switch-card v-model="features.hangHui">行会</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.baiTan">摆摊</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.haoYou">好友</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.sheZhi">设置</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="features.qiMa">骑马</switch-card>
        </el-col>
      </el-row>
    </template>
    <el-skeleton :rows="19" animated v-else />
  </el-card>
</template>

<script>
import * as Api from "@/api";
import { useRoute } from "vue-router";
import SwitchCard from "@/components/SwitchCard.vue";
import _ from 'underscore'
import { ElMessageBox } from "element-plus";
export default {
  setup() {
    const route = useRoute();
    const { features, backup } = Api.getGameFeaturesWithBackupRef(
      route.params.id
    );
    const isChanged = () => {
      if (backup) {
        return !_.isEqual({ ...features.value }, backup);
      }
      return false;
    };
    return {
      features,
      isChanged
    };
  },
  methods: {
    setFeatures() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameFeaturesApi(this.$route.params.id, this.features)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.features = data.data;
        })
        .finally(loading.close);
    },
    setFeaturesWithSync() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameFeaturesWithSyncApi(this.$route.params.id, this.features)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.features = data.data;
        })
        .finally(loading.close);
    },
    saveAndSyncChange() {
      if (this.isChanged()) {
        ElMessageBox.alert("确认保存并将修改项同步到其他分区？", "警告", {
          confirmButtonText: "确认",
          type: "warning",
          callback: action => {
            if (action === "confirm") {
              this.setFeaturesWithSync();
            }
          }
        });
      } else {
        Api.errorMessage("没有数据发生改变");
      }
    }
  },
  components: {
    SwitchCard
  }
};
</script>
