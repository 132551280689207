<template>
  <div class="btn-dialog">
    <el-dialog :title="title" v-model="show" width="500px" top="100px">
      <el-form :model="form" label-width="80px">
        <el-form-item label="是否显示：" prop="dsp">
          <el-switch v-model.number="form.dsp" :active-value="1" :inactive-value="0" />
        </el-form-item>
        <el-form-item label="图片路径" prop="picPath">
          <el-input v-model="form.picPath"></el-input>
        </el-form-item>
        <el-form-item label="按钮形态" prop="btnStyle">
          <el-input-number v-model="form.btnStyle" />
        </el-form-item>
        <el-form-item label="触发命令：" prop="cmd">
          <el-input v-model="form.cmd"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import * as Api from "@/api";
export default {
  emits: ["put-after"],
  data() {
    return {
      title: "修改新按钮",
      show: false,
      form: {
        dsp: 0,
        picPath: 0,
        cmd: "",
        btnStyle: 0,
      },
      index: -1,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    setInit(index, button) {
      this.index = index;
      this.$nextTick(() => {
        Object.assign(this.form, button);
      });
    },
    submit() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.setGameNewButtonApi(this.form)
        .then(({ data }) => {
          this.$emit("put-after", this.index, data.data);
          this.show = false;
        })
        .finally(loading.close);
    },
  },
};
</script>

<style lang="scss">
.btn-dialog {
  .el-form {
    width: 300px;
  }
}
</style>