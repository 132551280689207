<template>
  <el-card shadow="hover" body-style="padding: 20px;display:flex;justify-content: space-between;">
    <span class="my-card"><slot></slot></span>
    <el-switch style="width:40px;" v-model="modelValue" @change="$emit('update:modelValue', $event)" :disabled="disabled"></el-switch>
  </el-card>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
        disabled: {
          type: Boolean,
          default: false,
        }
    },
    emits: ['update:modelValue'],
};
</script>
<style>
.my-card {
  overflow: hidden;
  white-space:nowrap;
  line-height:20px;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>