<template>
  <div style="height:48px;text-align: right;">
    <el-button @click="asyncGold">同步到其他分区</el-button>
  </div>
  <el-card class="outer-card radius-20" v-loading="loading">
    <div>
      <el-scrollbar>
        <el-table :data="golds" border style="width: auto">
          <el-table-column
            align="center"
            prop="varName"
            label="货币类型"
            width="180"
          />
          <el-table-column
            align="center"
            prop="goldName"
            label="自定义名称"
            width="180"
          />
          <el-table-column
            align="center"
            prop="showUI"
            label="显示UI"
            width="180"
          />
          <el-table-column align="center" prop="x" label="X" />
          <el-table-column align="center" prop="y" label="y" />
          <el-table-column align="center" prop="open" label="启用" />
          <el-table-column align="center" prop="topDsp" label="顶部显示" />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-button
                @click.prevent="openEditGoldDialog(scope.$index)"
                size="small"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </div>
  </el-card>
  <GoldDialog ref="goldDialog" destroy-on-close @put-after="modifyGold" />
</template>

<script>
import GoldDialog from "@/components/GoldDialog.vue";
import * as Api from "@/api";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const { golds, loading } = Api.getGameCustomGoldRef(route.params.id);
    const asyncGold = () => {
      loading.value = true;
      Api.asyncCustomGold(route.params.id)
        .then(res => {
          Api.sucessMessage("修改成功");
          console.log(res);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return {
      golds,
      loading,
      asyncGold
    };
  },
  methods: {
    openEditGoldDialog(index) {
      let editDialog = this.$refs.goldDialog;
      editDialog.setInit(index, this.golds[index]);
      editDialog.open();
    },
    modifyGold(index, gold) {
      Api.sucessMessage("修改成功");
      Object.assign(this.golds[index], gold);
    }
  },
  components: {
    GoldDialog
  }
};
</script>
