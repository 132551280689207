<template>
  <div style="height:48px;text-align: right;">
    <el-button @click="asyncButton" >同步到其他分区</el-button>
  </div>
  <el-card class="outer-card radius-20" v-loading="loading">
    <div style="box-sizing:border-content;">
      <el-scrollbar>
        <el-table :data="buttons" border>
          <el-table-column align="center" prop="num" label="编号" width="100" />
          <el-table-column
            align="center"
            prop="dsp"
            label="是否显示"
            width="100"
          />
          <el-table-column align="center" prop="picPath" label="图片地址" />
          <el-table-column align="center" prop="btnStyle" label="按钮形态" />
          <el-table-column align="center" prop="cmd" label="脚本命令" />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-button
                @click.prevent="openEditButtonDialog(scope.$index)"
                size="small"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </div>
  </el-card>
  <NewButtonDialog
    ref="newButtonDialog"
    destroy-on-close
    @put-after="modifyButton"
  />
</template>

<script>
import NewButtonDialog from "@/components/NewButtonDialog.vue";
import * as Api from "@/api";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const { buttons, loading } = Api.getGameNewButtonRef(route.params.id);
    const asyncButton = () => {
      loading.value = true;
      Api.asyncCustomNewButton(route.params.id)
        .then(res => {
          Api.sucessMessage("修改成功");
          console.log(res);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return {
      buttons,
      loading,
      asyncButton
    };
  },
  methods: {
    openEditButtonDialog(index) {
      let editDialog = this.$refs.newButtonDialog;
      editDialog.setInit(index, this.buttons[index]);
      editDialog.open();
    },
    modifyButton(index, button) {
      Api.sucessMessage("修改成功");
      Object.assign(this.buttons[index], button);
    }
  },
  components: {
    NewButtonDialog
  }
};
</script>
