<template>
  <div class="plugin-wrap">
    <div style="height:48px; text-align: right;">
      <template v-if="plugin" >
        <el-button @click="saveAndSyncChange"
          >保存并将修改项同步其他分区</el-button
        >
        <el-button class="ml-4" @click="setPlugin">保存设置</el-button>
      </template>
    </div>
    <el-card class="outer-card radius-20">
      <el-scrollbar height="740px">
        <div class="pa-5" v-if="plugin">
          <template v-if="plugin">
            <h4>基本设置</h4>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.onlyName">只显人名</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.chenghao">隐藏称号</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoPack">自动解包</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.showName">人物显名</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.xueTiao">显示血条</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.monName">怪物显名</switch-card>
              </el-col>
              <!-- <el-col :span="4">
                <switch-card v-model="plugin.heroDun" disabled>英雄开盾</switch-card>
              </el-col> -->
            </el-row>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.showExp">显示经验</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.yingYue">背景音乐</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.npcName">NPC显名</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.pingBiHum">屏蔽玩家</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.jingZhiMov"
                  >禁止点击移动</switch-card
                >
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.bbName">宠物显名</switch-card>
              </el-col>
            </el-row>

            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.pingBiMon">屏蔽怪物</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.jingZhiLock"
                  >禁止自动锁定</switch-card
                >
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shiTi">清理尸体</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.smooth">平滑移动</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.humShowBlue">护身显蓝</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.yanChiXianShi"
                  >延迟显示</switch-card
                >
              </el-col>
            </el-row>

            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.renWuJianZhuang"
                  >人物简装</switch-card
                >
              </el-col>

              <el-col :span="4">
                <switch-card v-model="plugin.guaiWuJianZhuang"
                  >怪物简装</switch-card
                >
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.wuQiJianZhuang"
                  >武器简装</switch-card
                >
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shuangYaoGan">双摇杆</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoWear">自动穿戴</switch-card>
              </el-col>
            </el-row>

            <el-row :gutter="12" class="my-6">
              <el-col :span="12">
                <el-card shadow="hover">
                  <div class="flex justify-content-between align-items-center">
                    <span class="demonstration mr-8">游戏音量</span>
                    <el-slider
                      v-model="plugin.youXiYinLiang"
                      style="flex: 1"
                    ></el-slider>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card shadow="hover">
                  <div class="flex justify-content-between align-items-center">
                    <span class="demonstration mr-8">视图缩放</span>
                    <el-slider
                      v-model="plugin.shiTuSuoFang"
                      style="flex: 1"
                      :step="25"
                      :min="100"
                      :max="150"
                      :format-tooltip="value => value + '%'"
                    ></el-slider>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-divider />
          </template>
          <template v-if="plugin">
            <h4>职业设置</h4>
            <h5>综合</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.autoLianGong">自动练功</switch-card>
                
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.daoShiZhiLiao">自动治疗</switch-card>
                
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.daoShiDanTi">单体技能</switch-card>
                
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.daoShiQunTi">群体技能</switch-card>
                
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.youXianShiQuWuPin">优先拾取物品</switch-card>

              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.guaJiZiDongDuoBi">挂机自动躲避</switch-card>
              </el-col>
            </el-row>

            <h5>战士</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.daoDaoCiSha">刀刀刺杀</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoLieHuo">自动烈火</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.geWeiCiSsha">隔位刺杀</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.banYue">智能半月</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.baoYue">智能抱月</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoZhuRi">自动逐日剑法</switch-card>
              </el-col>
            </el-row>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.autoKaiTian">自动开天斩</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoLongYing">自动龙影剑法</switch-card>
              </el-col>
            </el-row>

            <h5>法师</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.chiXuDun">持续开盾</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.chixuShifa">持续施法</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shouDongBPXiao">手动冰咆哮</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shouDongBLHYan">手动爆裂火焰</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shouDongLXHYu">手动流星火雨</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shouDongJGDYing">手动疾光电影</switch-card>
              </el-col>
            </el-row>
            <h5>道士</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.autoFu">自动毒符</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.daoShiShiDu">自动施毒术</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.daoShiBB">自动召唤宠物</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.chiXuYingShen">持续隐身</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoMagicMo">自动幽灵盾</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoMagicMFang">自动圣神战甲术</switch-card>
              </el-col>
            </el-row>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.myZiYu">治疗术只对自己释放</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shouDongXFengPo">手动飓风破</switch-card>
              </el-col>
            </el-row>
            <el-divider />
          </template>

          <template v-if="plugin">
            <h4>保护设置</h4>
            <h5 class="sub-title">普通药品</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.autoHP">自动HP</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.autoMP">自动MP</switch-card>
              </el-col>
            </el-row>
            <h5 class="sub-title">瞬回取药</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.shunAutoHP">自动HP</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.shunAutoMP">自动MP</switch-card>
              </el-col>
            </el-row>
            <h5 class="sub-title">保护</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.chuanSongHP">传送HP</switch-card>
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.chuanSongMP">传送MP</switch-card>
              </el-col>
            </el-row>
          </template>

          <template v-if="plugin">
            <h4>英雄设置</h4>
            <h5 class="sub-title">普通药物</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.heroAutoHP">自动HP</switch-card>
              </el-col>
            </el-row>
            <h5 class="sub-title">瞬间药品</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.heroShunAutoHP"
                  >自动HP</switch-card
                >
              </el-col>
              <el-col :span="4">
                <switch-card v-model="plugin.heroShunAutoMP"
                  >自动MP</switch-card
                >
              </el-col>
            </el-row>
            <h5 class="sub-title">回收设置</h5>
            <el-row :gutter="12" class="my-6">
              <el-col :span="4">
                <switch-card v-model="plugin.heroZhaoHui">自动召回</switch-card>
              </el-col>
            </el-row>
          </template>
        </div>
      </el-scrollbar>
    </el-card>
  </div>
</template>

<script>
import * as Api from "@/api";
import { useRoute } from "vue-router";
import SwitchCard from "@/components/SwitchCard.vue";
import _ from 'underscore';
import { ElMessageBox } from "element-plus";
export default {
  setup() {
    const route = useRoute();
    const { plugin, backup } = Api.getGamePluginWithBackupRef(route.params.id);
    const isChanged = () => {
      if(backup) {
        return !_.isEqual({...plugin.value}, backup);
      }
      return false;
    }
    return {
      plugin,
      isChanged
    };
  },
  methods: {
    setPlugin() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGamePluginApi(this.$route.params.id, this.plugin)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.plugin = data.data;
        })
        .finally(loading.close);
    },
    setPluginWithSync() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGamePluginWithSyncApi(this.$route.params.id, this.plugin)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.plugin = data.data;
        })
        .finally(loading.close);
    },
    saveAndSyncChange() {
      if(this.isChanged()) {
        ElMessageBox.alert('确认保存并将修改项同步到其他分区？', "警告", {
          confirmButtonText: "确认",
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              this.setPluginWithSync();
            }
          },
        });
      } else {
        Api.errorMessage("没有数据发生改变");
      }
    }
  },
  components: {
    SwitchCard
  }
};
</script>

<style lang="scss">
.plugin-wrap {
  .outer-card > .el-card__body {
    padding: 0;
  }
  .sub-title {
    color: #666;
  }
}
</style>
