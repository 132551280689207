<template>
  <div style="text-align: right;height: 48px;">
    <el-button v-if="activity" style="margin-right:32px;" @click="saveAndSyncChange"
      >保存并将修改项同步其他分区</el-button
    >
    <el-button v-if="activity" @click="setActivity">保存设置</el-button>
  </div>
  <el-card class="outer-card radius-20">
    <template v-if="activity">
      <el-row :gutter="36" class="my-6">
        <el-col :span="4">
          <switch-card v-model="activity.huiYuan">会员</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.fuLi">福利</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.zuanShi">钻石</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.huoDong">活动</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.shangCheng">商城</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.paiHang">排行</switch-card>
        </el-col>
      </el-row>

      <el-row :gutter="36" class="my-6">
        <el-col :span="4">
          <switch-card v-model="activity.chongZhi">充值</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.cangBaoGe">藏宝阁</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.shouChong">首充</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.lingJiang">领奖</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.custom1">自定义按钮1</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.custom2">自定义按钮2</switch-card>
        </el-col>
      </el-row>

      <el-row :gutter="36" class="my-6">
        <el-col :span="4">
          <switch-card v-model="activity.custom3">自定义按钮3</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="activity.custom4">自定义按钮4</switch-card>
        </el-col>
      </el-row>
    </template>
    <el-skeleton :rows="19" animated v-else />
  </el-card>
</template>

<script>
import * as Api from "@/api";
import { useRoute } from "vue-router";
import SwitchCard from "@/components/SwitchCard.vue";
import _ from 'underscore';
import { ElMessageBox } from "element-plus";
export default {
  setup() {
    const route = useRoute();
    const { activity, backup } = Api.getGameActivityWithBackupRef(route.params.id);
    const isChanged = () => {
      if(backup) {
        return !_.isEqual({...activity.value}, backup);
      }
      return false;
    }
    return {
      activity,
      isChanged,
    };
  },
  methods: {
    setActivity() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameActivityApi(this.$route.params.id, this.activity)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.activity = data.data;
        })
        .finally(loading.close);
    },
    setActivityWithSync() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameActivityWithSyncApi(this.$route.params.id, this.activity)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.activity = data.data;
        })
        .finally(loading.close);
    },
    saveAndSyncChange() {
      if(this.isChanged()) {
        ElMessageBox.alert('确认保存并将修改项同步到其他分区？', "警告", {
          confirmButtonText: "确认",
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              this.setActivityWithSync();
            }
          },
        });
      } else {
        Api.errorMessage("没有数据发生改变");
      }
    }
  },
  components: {
    SwitchCard
  }
};
</script>
