<template>
  <div style="text-align: right;height: 48px;">
    <el-button v-if="basic" @click="saveAndSyncChange"
      >保存并将修改项同步其他分区</el-button
    >
    <el-button v-if="basic" @click="setBasic">保存设置</el-button>
  </div>
  <el-card class="radius-20 outer-card basic-setting">
    <template v-if="basic">
      <el-row class="my-6" :gutter="12">
        <el-col :span="4">
          <switch-card v-model="basic.helmet">显示头盔</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="basic.quickRegister">一键注册</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="basic.autoHook">自动挂机</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="basic.simScreen">左侧任务框</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="basic.autoPick">一键拾取</switch-card>
        </el-col>
        <el-col :span="4">
          <switch-card v-model="basic.virtual">APP检测虚拟机</switch-card>
        </el-col>
      </el-row>

      <el-row class="my-6" :gutter="12">
        <el-col :span="8">
          <el-card shadow="hover">
            <span style="line-height:28px">移动速度</span>
            <el-input-number
              size="mini"
              class="float-right"
              v-model="basic.speed"
            ></el-input-number>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <span style="line-height:28px">文字大小</span>
            <el-input-number
              size="mini"
              class="float-right"
              v-model="basic.npcTxtSize"
            ></el-input-number>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <span style="line-height:28px">自动锁定范围</span>
            <el-input-number
              size="mini"
              class="float-right"
              v-model="basic.atkRange"
            ></el-input-number>
          </el-card>
        </el-col>
      </el-row>

      <el-row class="my-6" :gutter="12">
        <el-col :span="8">
          <el-card shadow="hover">
            <span style="line-height:28px">h5文字描边大小</span>
            <el-input-number
              size="mini"
              class="float-right"
              v-model="basic.h5FontMarginSize"
            ></el-input-number>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover" style="position:relative;">
            <span style="font-size:10px;position:absolute;left:8px;bottom:8px;color:#C62828;font-weight:bold;">（不小于大背包实际格子数量）</span>
            <span style="line-height:28px">Max背包格子</span>
            <el-input-number
              :min="0"
              size="mini"
              class="float-right"
              v-model="basic.maxPackSize"
            ></el-input-number>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <span style="line-height:28px;">UI模式：</span>
            <el-radio :label="0" v-model="basic.uiMode">战记</el-radio>
            <el-radio :label="1" v-model="basic.uiMode">盛大</el-radio>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="my-6" :gutter="12">
        <el-col :span="12">
          <el-card shadow="hover">
            <span style="line-height:28px">NPC对话框文字坐标微调</span>
            <div style="float: right;">
              x：<el-input-number
                size="mini"
                v-model="basic.npcX"
                controls-position="right"
              ></el-input-number>
              y：<el-input-number
                size="mini"
                v-model="basic.npcY"
                controls-position="right"
              ></el-input-number>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <span style="line-height: 28px;">游戏职业：</span>
            <el-radio :label="0" v-model="basic.jobMode">单职业男战士</el-radio>
            <el-radio :label="1" v-model="basic.jobMode">全职业全性别</el-radio>
            <el-radio :label="2" v-model="basic.jobMode">单职业女战士</el-radio>
            <el-radio :label="3" v-model="basic.jobMode"
              >单职业男女战士</el-radio
            >
          </el-card>
        </el-col>
      </el-row>
      <el-card class="my-6" shadow="hover">
        <div class="flex align-items-center">
          <div class="mr-9">
            <span>语音系统：</span>
            <el-switch v-model="basic.voice"></el-switch>
          </div>
          <div class="mr-9">
            语音服务器：
            <el-input
              size="mini"
              style="width:200px;"
              v-model="basic.voiceIP"
              :disabled="!basic.voice"
            ></el-input>
          </div>
          端口：
          <div class="mr-9">
            <el-input
              size="mini"
              style="width:100px;"
              v-model="basic.voicePort"
              :disabled="!basic.voice"
            ></el-input>
          </div>
        </div>
      </el-card>
      <el-row class="my-6">
        <el-col>
          <el-card shadow="hover">
            <div class="flex align-items-center">
              <div class="mr-9">
                <span>图片系统：</span>
                <el-switch v-model="basic.pic"></el-switch>
              </div>
              <div class="mr-9">
                服务器地址：
                <el-input
                  size="mini"
                  style="width:384px;"
                  v-model="basic.picUrl"
                  :disabled="!basic.pic"
                ></el-input>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </template>
    <el-skeleton :rows="20" animated v-else />
  </el-card>
</template>

<script>
import * as Api from "@/api";
import { useRoute } from "vue-router";
import SwitchCard from "@/components/SwitchCard.vue";
import {onMounted} from 'vue'
import _ from 'underscore';
import { ElMessageBox } from "element-plus";
export default {
  name: "gameBasic",
  setup() {
    const $route = useRoute();
    const { basic, backup } = Api.getGameBasicWithBackupRef($route.params.id);
    const isChanged = () => {
      if(backup) {
        return !_.isEqual({...basic.value}, backup);
      }
      return false;
    }
    return {
      basic,
      isChanged
    };
  },
  methods: {
    setBasic() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameBasicApi(this.$route.params.id, this.basic)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.basic = data.data;
        })
        .finally(loading.close);
    },

    setBasicWithSync() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.setGameBasicWithSyncApi(this.$route.params.id, this.basic)
        .then(({ data }) => {
          Api.sucessMessage("修改成功");
          this.basic = data.data;
        })
        .finally(loading.close);
    },

    saveAndSyncChange() {
      if(this.isChanged()) {
        ElMessageBox.alert('确认保存并将修改项同步到其他分区？', "警告", {
          confirmButtonText: "确认",
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              this.setBasicWithSync();
            }
          },
        });
      } else {
        Api.errorMessage("没有数据发生改变");
      }
    }
  },
  components: {
    SwitchCard
  }
};
</script>

<style></style>
