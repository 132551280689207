<template>
  <el-dialog
    title="同步设置"
    v-model="show"
    width="400px"
    top="200px"
    :before-close="beforeClose"
  >
    <el-form :model="form" status-icon ref="form" label-width="100px">
      <el-form-item label="模板：" prop="template">
        <el-select
          v-model.number="form.template"
          :loading="loading"
          placeholder="未选择模板"
        >
          <el-option
            v-for="(item, index) in templates"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              index
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submit">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import * as Api from "@/api";
export default {
  setup() {
    return {
      templates: Api.getTemplatesRef(),
    };
  },
  data() {
    return {
      show: false,
      loading: true,
      form: {
        template: 0,
      },
    };
  },
  methods: {
    open() {
      this.show = true;
      this.loading = false;
    },
    submit() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.syncGameConfigApi(
        parseInt(this.$route.params.id),
        this.form.template
      ).then(() => {
        Api.sucessMessage("同步成功");
      })
      .finally(loading.close)
    },
    beforeClose(done) {
      this.loading = true;
      done();
    },
  },
};
</script>