<template>
  <ButtonCard title="同步设置" describe="与其他游戏的设置进行同步" @click="openSyncDialog" />
  <SyncDialog ref="syncDialog" />
</template>
<script>
import ButtonCard from "@/components/CardButton.vue";
import SyncDialog from "@/components/SyncDialog.vue";
export default {
  methods: {
    openSyncDialog() {
      let syncDialog = this.$refs.syncDialog;
      syncDialog.open();
    },
  },
  components: {
    ButtonCard,
    SyncDialog,
  },
};
</script>