<template>
  <el-card shadow="hover" class="cbtn-card">
    <div class="cbtn-container">
      <div class="cbtn-left">
        <div class="cbtn-title">{{title}}</div>
        <div class="cbtn-describe" v-if="describe">{{describe}}</div>
      </div>
      <div class="cbtn-right">
        <i class="el-icon-caret-right"></i>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    title: String,
    describe: String,
  },
};
</script>

<style lang="scss">
$class-prefix: "cbtn";

.#{$class-prefix} {
  &-card {
    cursor: pointer;
    border-radius: 8px;
    background-color: #fff;
    
    &:hover {
      background-color: #eeeeee33;
    }
  }
  &-container {
    display: flex;
  }
  &-title {
    font-size: 20px;
    font-weight: bold;
  }
  &-describe {
    font-size: 16px;
    margin-top: 12px;
  }
  &-left {
      flex: 1;
  }

  &-right {
      width: 32px;
      font-size: 20px;
      color: #666;
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
</style>