<template>
  <div style="height:48px;text-align: right;">
    <el-button @click="asyncButton">同步到其他分区</el-button>
  </div>
  <el-card class="outer-card radius-20" v-loading="loading">
    <div style="box-sizing:border-content;">
      <el-scrollbar>
        <el-table :data="buttons" border >
          <el-table-column
            align="center"
            prop="name"
            label="按钮名称"
            width="180"
          />
          <el-table-column
            align="center"
            prop="cmd"
            label="触发命令"
            width="180"
          />
          <el-table-column align="center" prop="x" label="X" />
          <el-table-column align="center" prop="y" label="y" />
          <el-table-column align="center" prop="color" label="颜色" />
          <el-table-column align="center" prop="size" label="字体大小" />
          <el-table-column align="center" prop="open" label="启用" />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-button
                @click.prevent="openEditButtonDialog(scope.$index)"
                size="small"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </div>
  </el-card>
  <ButtonDialog ref="buttonDialog" destroy-on-close @put-after="modifyButton" />
</template>

<script>
import ButtonDialog from "@/components/ButtonDialog.vue";
import * as Api from "@/api";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const {buttons, loading}  = Api.getGameCustomButtonRef(route.params.id)
    const asyncButton = () => {
      loading.value = true;
      Api.asyncCustomButton(route.params.id)
      .then((res) => {
        Api.sucessMessage("修改成功");
      })
      .finally(() => {
        loading.value = false;
      })
    }

    return {
      buttons,
      loading,
      asyncButton
    };
  },
  methods: {
    openEditButtonDialog(index) {
      let editDialog = this.$refs.buttonDialog;
      editDialog.setInit(index, this.buttons[index]);
      editDialog.open();
    },
    modifyButton(index, button) {
      Api.sucessMessage("修改成功");
      Object.assign(this.buttons[index], button);
    },
  },
  components: {
    ButtonDialog,
  },
};
</script>