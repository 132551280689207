<template>
  <div class="gold-dialog">
    <el-dialog :title="title" v-model="show" width="500px" top="100px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="货币类型" prop="goldName">
          <el-input v-model="form.varName"></el-input>
        </el-form-item>
        <el-form-item label="自定义名称：" prop="varName">
          <el-input v-model="form.goldName"></el-input>
        </el-form-item>
        <el-form-item label="货币UI" prop="showUI">
          <el-input v-model="form.showUI"></el-input>
        </el-form-item>
        <el-form-item label="X坐标：" prop="x">
          <el-input-number v-model.number="form.x" />
        </el-form-item>
        <el-form-item label="y坐标：" prop="y">
          <el-input-number v-model.number="form.y" />
        </el-form-item>
        <el-form-item label="启用：" prop="open">
          <el-switch v-model.number="form.open" :active-value="1" :inactive-value="0" />
        </el-form-item>
        <el-form-item label="顶部显示：" prop="topDsp">
          <el-switch v-model.number="form.topDsp" :active-value="1" :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import * as Api from "@/api";
export default {
  emits: ["put-after"],
  data() {
    return {
      title: "修改自定义货币",
      show: false,
      form: {
        goldName: "",
        varName: "",
        x: 0,
        y: 0,
        showUI: "",
        open: 0,
        topDsp: 0
      },
      index: -1,
    };
  },
  methods: {
    setInit(index, init) {
      this.index = index;
      this.$nextTick(() => {
        Object.assign(this.form, init);
      });
    },
    open() {
      this.show = true;
    },
    submit() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.setGameCustomGoldApi(this.form)
        .then(({ data }) => {
          this.$emit("put-after", this.index, data.data);
          this.show = false;
        })
        .finally(loading.close);
    },
  },
};
</script>

<style lang="scss">
.gold-dialog {
  .el-form {
    width: 300px;
  }
}
</style>