<template>
  <div class="btn-dialog">
    <el-dialog :title="title" v-model="show" width="500px" top="100px" >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="按钮名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="X：" prop="x">
          <el-input-number v-model="form.x" />
        </el-form-item>
        <el-form-item label="y：" prop="y">
          <el-input-number v-model="form.y" />
        </el-form-item>
        <el-form-item label="触发命令：" prop="cmd">
          <el-input v-model="form.cmd"></el-input>
        </el-form-item>
        <el-form-item label="字体颜色：" prop="color">
          <el-input-number v-model="form.color" />
        </el-form-item>
        <el-form-item label="字体大小：" prop="size">
          <el-input-number v-model.number="form.size" />
        </el-form-item>
        <el-form-item label="启用：" prop="open">
          <el-switch v-model.number="form.open" :active-value="1" :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import * as Api from "@/api";
export default {
  emits: ["put-after"],
  data() {
    return {
      title: "修改自定义按钮",
      show: false,
      form: {
        name: "",
        x: 0,
        y: 0,
        cmd: "",
        size: 0,
        color: 0,
        open: 0,
      },
      index: -1,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    setInit(index, button) {
      this.index = index;
      this.$nextTick(() => {
        Object.assign(this.form, button);
      });
    },
    submit() {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.setGameCustomButtonApi(this.form)
        .then(({ data }) => {
          this.$emit("put-after", this.index, data.data);
          this.show = false;
        })
        .finally(loading.close);
    },
  },
};
</script>

<style lang="scss">
.btn-dialog {
  .el-form {
    width: 300px;
  }
}
</style>